@import '../../../style/color';

.suggestion-item,
.suggestion-item--active {
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }

  .anticon {
    color: $primary-color;
    margin: 0 10px;
  }

  .main-text {
    font-weight: bold;
  }

  .secondary-text {
    color: $contrast3;
    font-size: 0.8em;
  }
}

.suggestion-item--active {
  background-color: #e6ffee;
}
