@import '../../../style/color';

.ant-transfer-list-search-action {
  left: 12px;
  right: initial;

  .anticon-search {
    color: $primary-color;
  }
}

.ant-transfer-list-search {
  padding-left: 30px;
}

.ant-transfer-operation {
  .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding: 0 5px;
    background-color: $white;
    color: $primary-color;
  }

  .ant-btn .anticon {
    font-size: 15px;
  }
}
