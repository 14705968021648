@import '../../../style/color';

.upload-list {
  .ant-upload-text-icon {
    display: none;
  }

  .ant-upload-list-item-info, .ant-upload-list-item-name {
    padding: 0;
  }

  .ant-upload-span, .ant-upload-span svg {
    color: $primary-brand;
  }

  .ant-upload-list-item-name {
    text-decoration: underline;
  }

  & + .see-more {
    margin-top: 10px;
    padding: 0;
  }

  &.limited {
    .ant-upload-list {
      max-height: 18rem;
      overflow: hidden;

      .ant-upload-list-text-container {
        height: 3rem;
      }
    }
  }

  .ant-upload-list-item-actions svg {
    color: #fff;
  }
}
