@import '../../../style/color';
@import '../../../style/extend';

.ant-drawer-content-wrapper {
  min-width: 560px;
}

.app-drawer-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  // TODO: change to var
  padding: 0.75rem 1.6rem;
  text-align: right;
  background: $white;
  .ant-btn {
    margin-left: 1rem;
  }
}

.ant-modal-footer .app-drawer-footer {
  position: static;
  padding: 0;

}

.ant-drawer-title {
  @extend %app-title;
  margin-bottom: 3.8rem;
}

.ant-drawer-header {
  padding-top: 5rem;
  padding-bottom: 0;
  border: 0;
}

.ant-drawer-body {
  padding-top: 0;
}

.ant-drawer-footer {
  > * {
    margin-left: 8px;
    margin-bottom: 8px;
  }
  text-align: right;
  padding-bottom: 2px;
  .ant-divider {
    border: 0;
  }
}