@import '../../../style/extend';

.inverted .ant-calendar-panel {
  display: flex;
  flex-direction: column-reverse;
}

.ant-picker-input {
  input {
    @extend %app-input;
  }
}