@import '../../../style/color';

.path-container {
    font-size: 1.3rem;
    color: $contrast3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    &.ant-breadcrumb > span a, &.ant-breadcrumb > span {
        color: $contrast3;;        ;
    }
    &.ant-breadcrumb > span a:hover {
        color: $primary-brand;;        ;
    }
    .ant-breadcrumb-separator {
        margin: 0 4px;
    }
}
