// color
$lagoon-blue: #22dadb;
$black: #000;
$white: #fff;
$red: #f5222d;
$orange: #ffbe24;
$darkBlue: #182945;
// contrast
$dark-grey: #495457;
$medium-grey: #687677;
$light-grey: #859092;
$cold-white: #f5faf7;
$pearl-white: #fbfffe;
$light-white: #ffffff;

$primary-brand: #00c85c;
$secondary-brand: $lagoon-blue;
$primary-color: #53d4a8;

$contrast1: $dark-grey;
$contrast2: $medium-grey;
$contrast3: $light-grey;
$contrast4: $cold-white;
$contrast5: $pearl-white;
$contrast6: $light-white;

$link-active: #00a74f;
$border-table: #e8e8e8;

// incident status
$reported: #ffbe34;
$rejected: #ff3434;
$ongoing: #22dadb;
$resolved: #49d540;

// request status
$open: #36EFBA;
$closed: #ff3434;

// role
$admin: #ffd700;
$normal: $primary-brand;
