@import '../../../style/color';

.ant-comment-content-author-name {
  font-weight: 500;
}

.ant-comment-content-author-name > * {
  color: inherit;
  cursor: pointer;
}

.ant-comment-content-author-name,
.ant-comment-content-author-time {
  color: inherit;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.ant-comment-content-author {
    overflow: hidden;
    display: block;
}

.ant-comment-content-author-time {
    font-weight: 400;
    white-space: normal;
    .event {
        margin-left: -4px;
        margin-right: 8px;
    }
    .date {
      color: $contrast3;
    }
}

.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 1.5rem;
}

.ant-comment-actions {
  margin-top: 4px;

  .ant-btn {
    margin-right: 5px;
    padding: 0;
  }

  & > li:empty {
    display: none;
  }

  & > li > span {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .anticon-edit,
  .anticon-delete {
    color: $primary-brand;
  }

  .anticon-delete {
    border-bottom: 1px solid $primary-brand;
    padding-bottom: 1px;
    font-size: 1.3rem;
    vertical-align: baseline;
  }
}

.ant-comment-inner {
  padding: 10px 0;
}

.ant-comment.edited .ant-comment-actions > li {
  float: right;
}
