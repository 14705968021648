@import '../../../../style/color';

.help-header {
  background: #182945;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2 !important;
  .logo-container {
    float: left;
    width: 12rem;
    margin: 1.2rem 2.4rem .8rem 0;
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #182945;
    float: right;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
    color: $primary-brand;
    box-shadow: inset 0 -4px 0 $primary-brand;
  }
}
