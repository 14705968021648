.ant-time-picker-panel {
  width: 130px;
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 130px;
}

.ant-time-picker-panel-select {
  width: 65px;
}
