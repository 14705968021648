@import '../../../style/color';

.app-card {
    &.ant-card-bordered {
        border: 0;
        box-shadow: 0 .1rem .3rem 0 rgba(0, 0, 0, .2);
    }
    border-radius: .2rem;
    .ant-card-head {
        color: $contrast1;
    }
    .ant-card-body {
        padding: 2.4rem;
        .ant-card-meta-title {
            color: $contrast1;
            font-size: 1.5rem;
        }
        .ant-card-meta-description {
            color: $contrast2;
            font-size: 1.5rem;
        }
    }
    .ant-card-actions {
        background: $white;
        border-top: 0;
        padding-right: 2.4rem;
        box-shadow: 0 .1rem .3rem 0 rgba(0, 0, 0, .2);
    }
    &.section {
        &.ant-card-bordered {
            border: 0;
            box-shadow: none;
        }
        background: none;
        .ant-card-head {
            padding: 0;
            border-bottom: 0;
            margin-bottom: -.2rem;
            min-height: 3.6rem;
            .ant-card-head-title {
                padding-top: 0;
            }
            .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
                padding: 0;
                padding-bottom: 1.6rem;
            }
            .ant-tabs-bar {
                border-bottom: 0;
                margin: 0;
            }
            .ant-tabs-ink-bar {
                bottom: .2rem;
                border-radius: 3rem;
            }
            .ant-tabs {
                margin-bottom: 0;
            }
        }
        .ant-card-body {
            border-radius: .2rem;
            box-shadow: 0 .1rem .3rem 0 rgba(0, 0, 0, .2);
            background: $white;
        }
    }
    &.dashboard {
        .ant-card-head {
            min-height: 0;
            color: $contrast2;
            font-size: 15px;
            border-bottom: none;
            .ant-card-head-title {
                padding-bottom: 0;
            }
        }
    }
}
