@import '../../../style/extend';
@import '../../../style/color';

.app-modal-delete {
  .ant-btn-danger {
    background: $red;
    color: $white;
  }
  .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: $red;
  }
}
