@import '../../../style/color';

.ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item,
.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  background-color: $contrast5;
}

.ant-progress-inner {
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: $primary-brand;
  }
}

.ant-upload-list-picture-card {
  float: none;
}

.ant-upload {
  color: $contrast1;
}
