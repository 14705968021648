@import '../../../style/color';

.ant-alert {
  .anticon {
    font-size: 2.5rem;
  }

  .app-btn {
    margin-left: 20px;
  }

  .ant-alert-message {
    margin-left: 10px;
  }
}

.incident-acceptation-alert {
  background-color: lighten($primary-brand, 55);
  border-color: $primary-brand;
}
