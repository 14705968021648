@import '../../../style/color';

.container {
  background: $white;
  display: flex;
  // right block
  .homepage-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    border-radius: 0.5rem;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    .utility {
      .homepage-header {
        padding: 7rem 2.4rem 0rem 2.4rem;
        margin: 0 auto;
        width: 100%;
        max-width: 42.5rem;
        margin-bottom: 2.4rem;
        .logo {
          margin-bottom: 1.6rem;
          display: block;
        }
        .baseline {
          text-align: left;
          font-weight: 600;
          color: $darkBlue;
          font-size: 2.4rem;
          line-height: 1.3;
        }
      }
      // content
      .slider {
        width: 100%;
        margin-bottom: 5rem;
        overflow: hidden;
        .slider-container {
          &.translated {
            transform: translate3d(-50%, 0, 0);
            transition-duration: 0.5s;
          }
          .item {
            width: 100%;
            opacity: 0;
            transition-duration: 0.2s;
            &.active {
              opacity: 1;
              transition-duration: 0.2s;
            }
            .content {
              padding: 0rem 3rem;
              margin: auto;
              max-width: 42.5rem;
              .reset-link {
                text-align: right;
                &.back {
                  display: flex;
                  justify-content: space-between;
                  text-align: left;
                }
                .ant-btn {
                  padding: 0;
                }
              }
              .app-btn-submit {
                display: block;
                max-width: 365px;
                width: 100%;
                margin-bottom: 1rem;
              }
              .anticon-check {
                color: $primary-brand;
                margin-right: 0.6rem;
              }
            }
          }
        }
      }
    }
    .homepage-footer {
      padding: 2.4rem;
      text-align: center;
    }
  }
  // left block
  .image-container {
    text-shadow: 10px 5px 30px $secondary-brand;
    width: 50%;

    background: url(/images/illustration-brand-dark.svg) no-repeat center,
      linear-gradient(to top, $primary-brand 0%, $secondary-brand 100%)
        no-repeat center center;
    background-size: cover;
    min-height: 100vh;
    &.right-block {
      display: none;
    }
  }
}

@media all and (max-width: 768px) {
  .container .homepage-container {
    width: 100%;
    justify-content: center;
  }
  .container .image-container {
    width: 100%;
    &.left-block {
      display: none;
    }
    &.right-block {
      min-height: auto;
      height: 20rem;
      display: block;
    }
  }
}
