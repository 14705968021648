@import '../../../style/color';

.app-message-handler {
  .ant-affix {
    z-index: 100;
    .app-message-writer {
      box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, .15);
      transition-duration: 0.3s;
    }
  }

  & > .app-btn {
    display: block;
    margin: 0 auto;
  }

  .app-message-writer {
    z-index: 100;
    background: white;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .06);
    transition-duration: 0.3s;
    padding-bottom: 8px;
    .app-input {
      min-height: 3.2rem;
    }

    .ant-input::placeholder {
      font-style: normal;
    }

    .ant-comment-content-detail {
      display: flex;
      .app-btn {
        text-transform: initial;
        padding: 0 15px;
        float: right;
        margin-top: 0px;
        margin-left: 5px;
      }

      .comment-textarea-post {
        &.ant-input {
          font-size: 1.6rem;
          padding: 6.5px 11px;
        }
      }

      @media (max-width: 992px) {
        span.text {display:none}
    }
    }
  }
}

.ant-comment-avatar {
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}

.app-message-error {
  text-align: center;
}
