@import '../../../style/color';

.incident-status-timeline {
  margin-top: 12px;

  .ant-steps-item-title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .ant-steps-item-description {
    font-size: 1.2rem;
    display: none;
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 200px;
  }

  .ant-steps-item-process .ant-steps-item-description,
  .ant-steps-item-finish .ant-steps-item-description {
    display: block;
    color: $contrast2;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }

  .reported {
    &.ant-steps-item-finish,
    &.ant-steps-item-process {
      .ant-steps-item-icon,
      .ant-steps-item-content > .ant-steps-item-title::after,
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
      &.ant-steps-item-finish .ant-steps-item-tail::after {
        background-color: $reported;
        border-color: $reported;
      }

      .ant-steps-item-title {
        color: $reported;
      }
    }
  }

  .rejected {
    &.ant-steps-item-finish,
    &.ant-steps-item-process {
      .ant-steps-item-icon,
      .ant-steps-item-content > .ant-steps-item-title::after,
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
      &.ant-steps-item-finish .ant-steps-item-tail::after {
        background-color: $rejected;
        border-color: $rejected;
      }

      .ant-steps-item-title {
        color: $rejected;
      }
    }
  }

  .ongoing {
    &.ant-steps-item-finish,
    &.ant-steps-item-process {
      .ant-steps-item-icon,
      .ant-steps-item-content > .ant-steps-item-title::after,
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
      &.ant-steps-item-finish .ant-steps-item-tail::after {
        background-color: $ongoing;
        border-color: $ongoing;
      }

      .ant-steps-item-title {
        color: $ongoing;
      }
    }
  }

  .resolved {
    &.ant-steps-item-finish,
    &.ant-steps-item-process {
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
      &.ant-steps-item-finish .ant-steps-item-tail::after {
        background-color: $resolved;
      }

      .ant-steps-item-title {
        color: $resolved;
      }
    }
  }

  .intervention-link {
    cursor: pointer;
    .anticon {
      color: $primary-color;
    }
  }
}

.intervention-button-container {
  text-align: center;
}