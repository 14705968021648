@import '../../../style/color';
@import '../../../style/extend';

.filters-container {
  margin-bottom: 24px;
  .ant-picker-suffix,
  .ant-picker-prefix,
  .ant-select-arrow,
  .ant-input-affix-wrapper {
    color: $contrast3;
  }

  .main-filters-container {
    height: 40px;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    background: $white;
    .ant-select > .ant-select-selector:hover,
    .ant-select-auto-complete .ant-input:hover,
    .ant-cascasder-picker .ant-input:hover,
    .ant-input-wrapper .ant-input:hover, .ant-select-arrow,
    .ant-picker:hover {
      z-index: 1;
    }
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:focus,
    .ant-select-focused,
    .ant-picker-focused,
    .ant-select-single:not(.ant-select-customize-input)::after {
      z-index: 2;
    }
    .ant-select,
    .ant-tree-select,
    .ant-select-selector,
    .ant-select-focused,
    .ant-select-single,
    .ant-select-show-arrow,
    .ant-select-open,
    .ant-picker {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      float: inherit;
      vertical-align: inherit;
      margin-left: inherit;
      border-radius: inherit;
    }
    .outside-click-handler-container,
    .no-outside-click-event {
      width: 100%
    }
    .ant-input-group-addon {
      background: $white;
    }
    .app-btn {
      flex: unset;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    @media (max-width: 1200px) {
      .reset-button > span:last-child {
        display: none;
      }
    }
  }
  
  .extra-filters-container {
    display: flex;
    .content {
      > div {
        margin-right: 8px;
      }
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 8px;
      .no-outside-click-event {
        display: initial;
      }
      .outside-click-handler-container {
        width: 188px;
      }
      & > * {
        margin-top: 8px;
      }
      .ant-select-selection-item {
        padding-top: 2px;
      }
    }
    .more {
    padding-top: 16px;
    }
  }
}

// Filters in card
.ant-card {
  .filters-container .main-filters-container {
    height: 31px;
    .app-btn {
        height: 31px;
    }
    .ant-select-selector {
        height: 31px;
    }
    .reset-button {
      padding: 3.2px 15px;
      font-size: 15px;
    }
  }
}

// Mobile and tablet filters content 
.filters-drawer {
  .ant-drawer-content-wrapper {
    width: 80% !important;
    min-width: auto;
  }
  .ant-picker-suffix,
  .ant-picker-prefix,
  .ant-select-arrow,
  .ant-input-affix-wrapper {
      color: $contrast3;
  }
  .ant-drawer-body > * {
    margin-bottom: 8px;
  }
  .ant-picker {
    width: 100%;
  }
}