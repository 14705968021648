@import '../../../style/color';

.app-form-item {
  width: 100%;
  margin-right: 0 !important;
  margin-bottom: 1rem !important;
  &.ant-form-item-with-help {
    margin-bottom: 0.8rem !important;
  }
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    line-height: normal;
    display: block;
    width: 100%;
    margin-top: 0.1rem;
    margin-bottom: 1.2rem;
    font-weight: 500;
    text-align: left;
    white-space: normal;
    .ant-form-item-required {
      &::after,
      &::before {
        display: none;
      }
    }
    label {
      color: $contrast1;
      &::after {
        content: ' - facultatif';
        font-style: italic;
      }
      &::before {
        display: none;
      }
    }
  }
  .ant-form-item-required::before {
    color: red;
  }
  .ant-form-explain,
  .ant-form-extra {
    margin-top: 0;
  }
  .ant-transfer-list-header {
    color: $contrast1;
  }
  .ant-transfer {
    color: $contrast2;
  }
}
