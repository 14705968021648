@import '../../../style/color';

.app-text {
  font-size: 1.5rem;
}

.app-text.light {
  font-weight: 300;
}

.app-text.regular {
  font-weight: 400;
}

.app-text.italic {
  font-weight: 400;
  font-style: italic;
}

.app-text.medium {
  font-weight: 500;
}

.app-text.bold {
  font-weight: 700;
}

.app-text.highlight {
  background: $primary-brand;
}

.app-label {
  display: block;
  color: $contrast1;
  margin-bottom: 1.2rem;
  font-weight: 500;
}

.app-smallable-label .app-label {
  font-size: 0.8em;
}

.app-description {
  display: block;
  color: $contrast2;
  margin-bottom: 3.2rem;
  font-weight: 400;
  margin-left: -0.8rem;
  width: calc(100% + 1.6rem);
  border: 0.8rem solid rgba($cold-white, 0);
  white-space: pre-line;
  .icon-container {
    flex: 0 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }
  &.editable-container {
    display: flex;
    margin-left: -0.8rem;
    width: calc(100% + 1.6rem);
    border: 0.8rem solid rgba($cold-white, 0);
    border-radius: 0.2rem;
    transition-duration: 0.2s;
    &:hover {
      border-color: rgba($cold-white, 1);
      cursor: pointer;
      background: $cold-white;
      .icon-container {
        .edit-icon {
          opacity: 1;
          color: $primary-brand;
        }
      }
    }
    .data-container {
      width: 100%;
    }
    .icon-container {
      opacity: 1;
      .edit-icon {
        opacity: 0;
        transition-duration: 0.2s;
      }
    }
  }
}

.app-none {
  font-style: italic;
  color: $contrast3;
}

.app-details {
  font-size: 1.5rem;
  color: $contrast2;
  margin-bottom: 3.2rem;
  font-weight: 400;
}

.app-markdown {
  font-size: 0.8em;
  color: $contrast3;
}

.app-blockquote {
  display: block;
  color: $contrast3;
  margin: 0.4rem 0;
  padding-left: 10px;
  border-left: 2px solid #f0f0f0;
  margin-bottom: 8px;
}
