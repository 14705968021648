@import '../../../style/color';

.ant-table-cell .app-avatar {
  & + .app-avatar {
    margin-left: -10px;
  }

  & + .ant-btn {
    background-color: white;
    border-radius: 50px;
    padding: 0;
    width: 36px;
    height: 36px;
    margin-left: 5px;
  }
}
