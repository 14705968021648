.app-tabs {
  margin: -0.8rem;
  margin-top: 0rem;
  .ant-tabs-bar {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .ant-tabs-tabpane {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .ant-table {
    margin-top: -1.6rem;
  }
}

.ant-card-head-tabs > .ant-tabs-nav {
  margin-bottom: 0;
}

.ant-tabs-nav .ant-tabs-tab {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
