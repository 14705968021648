@import '../../../style/color';

.filterable-select {
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
  .ant-select-selection-overflow-item:first-child {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
  }
  .ant-select-selection-overflow-item-rest .ant-select-selection-item {
    background: #e6ffee;
    border: none;
    color: $primary-brand;
    font-weight: 700;
  }
  .ant-select-selection-item {
    background: $white;
    border: none;
    .ant-tag {
      margin-right: 0
    }
    .ant-select-selection-item-remove {
      display: none;
    }
  }
}

.search-container {
  margin: 8px;
}