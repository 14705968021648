@import '../../../style/extend';
@import '../../../style/color';

.app-cascader {
  position: relative;
  background: $contrast4;
  .ant-cascader-input {
    position: relative;
    padding-right: 4rem;
  }
  .ant-cascader-input:hover {
    border: 0.1rem solid $primary-brand;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }
  &::after {
    position: absolute;
    content: '';
    border-right: 0.1rem solid $contrast3;
    top: 10px;
    bottom: 10px;
    width: 0;
    right: 3.3rem;
  }
  i {
    color: $contrast3;
  }
}
