@import '../../../style/color';

.tags-dropdown {
  overflow: hidden !important;
  max-height: none !important;
}

.tag-input-container {
  width: 100%;
  height: 40px;
  padding: 8px;
  padding-bottom: 4px;
  border-top: 1px solid $border-table;
  background: $white;
  display: flex;
  align-items: center;
  align-content: flex-start;
    .tag-input {
      margin-right: 8px;
    }
}