@import '../../../style/color';

.app-histories {
    max-height: 562px;
    overflow: auto;

    .app-history + .app-history {
        margin-top: 10px;
    }

    .ant-empty {
        margin: 57px 8px
    }
}