@import '../../../style/color';

.app-item-popover {
  cursor: pointer;

  &, &:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  & + & {
    margin-top: 10px;
  }

  &.delete {
    color: $red;
  }
}

.app-popover-user-card {
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-comment-content-author-name > * {
    color: inherit;
    cursor: pointer;
  }
}
