.app-logo {
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 4.2rem;
  align-items: center;
  svg.app-logo-text {
    max-width: 56%;
    height: 80%;
    margin-left: 10%;
    margin-top: -5%;
    visibility: visible;
    opacity: 1;
    transition-duration: 0.2s;
  }
  svg.app-logo-icon {
    transition-duration: 0.2s;
    max-width: 30%;
    height: 96%;
  }

  &.minified {
    svg.app-logo-text {
      opacity: 0;
      visibility: hidden;
    }
  }
}
