@import '../../../style/color';
@import '../../../style/extend';

.ant-select {
  @extend %app-input;
  width: 100%;
}

.ant-select-selection-multiple {
  cursor: pointer;
}

.ant-select-dropdown-menu {
  max-height: none;
}
