@import '../../../../style/color';

.app-drawer-add-user {
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    .ant-drawer-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-bottom: 8rem;
      .ant-card {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 2rem;
        cursor: pointer;
        .app-description {
          display: block;
          text-align: center;
          margin: 0;
        }
      }
      .ant-card:hover {
        background: $cold-white;
      }
    }
  }
}
