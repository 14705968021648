@import '../../../style/color';

%app-title {
  position: relative;
  padding-top: 0.9rem;
  color: $contrast2;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 0.3rem;
    border-top: 0.3rem solid $primary-brand;
  }
}

%app-input {
  color: $contrast2;
}

.centerText {
  text-align: center;
}
