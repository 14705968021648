.app-multiple-selector {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    .no-outside-click-event {
        width: inherit;
    }

    .ant-select-selection-search-input {
        padding-right: 0 !important;
    }

    .ant-select-selection-search-input {
        padding-right: 0 !important;
    }
}
