@import '../../../style/color';

.app-message-list {
  margin-top: 32px;
  z-index: 0;

  .ant-comment-inner {
    padding-top: 0;
    padding-bottom: 3.2rem;
  }

  .ant-comment-content-detail p {
    display: inline-block;
    background: $contrast4;
    color: $contrast2;
    padding: .8rem 1.1rem;
    border-radius: .4rem;
    .app-input {
      width: 100%;
    }
  }
}
