@import '../../../style/extend';
@import '../../../style/color';

.ant-select-single:not(.ant-select-customize-input) {
  &::after {
    position: absolute;
    content: '';
    border-right: 0.1rem solid #d9d9d9;
    top: 8px;
    bottom: 8px;
    width: 0;
    right: 3.3rem;
  }
}
