@import '../../../../style/color';

// sidebar
.ant-layout-sider {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  min-height: 100% !important;
}

.ant-layout-sider-children {
  position: fixed;
}

.ant-menu-inline {
  border: 0;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-inline-collapsed {
  border: 0;
  .ant-menu-item-selected::before {
    border-color: $primary-brand !important;
  }
}

.app-side-menu {
  li:first-child {
    margin-top: 0;
  }
  li:last-child {
    margin-bottom: 0;
  }
  .ant-menu-item {
    padding-left: 32px !important;
    font-size: 1.4rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      border-right: 0.4rem solid white;
      box-sizing: border-box;
      transition-duration: 0.2s;
    }
    .anticon {
      margin-right: 1.6rem;
    }
    .ant-layout-sider-collapsed & {
      padding-left: 2.4rem !important;
      .anticon {
        font-size: 2rem;
      }
    }
  }
  .ant-menu-item:hover {
    background: $contrast4;
    transition-duration: 0.2s;
    &::before {
      border-right: 0.4rem solid $contrast1;
    }
    a {
      color: $contrast1;
    }
  }
  &.ant-menu-vertical .ant-menu-item::after,
  &.ant-menu-vertical-left .ant-menu-item::after,
  &.ant-menu-vertical-right .ant-menu-item::after,
  &.ant-menu-inline .ant-menu-item::after {
    left: 0;
    right: unset;
    border-right-width: 0.4rem;
  }
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:active {
    background: $contrast4;
  }
  .ant-menu-item.ant-menu-item-selected > a {
    color: rgba($primary-brand, 100%);
  }
}

// more space to scroll
.ant-layout-sider.ant-layout-sider-has-trigger {
  padding-bottom: 8.8rem;
}

// button create
.ant-btn-round.ant-btn-lg {
  padding: 0;
  position: relative;
}

.app-create-new {
  margin: 2.4rem auto;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  background: linear-gradient(
    to left,
    $primary-brand 0%,
    $secondary-brand 100%
  ) !important;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.2);
  border: 0;
  transition-duration: 0.1s;
  width: 18rem;
  height: 4.2rem !important;
  &:hover {
    background: linear-gradient(
      to left,
      #05d466 0%,
      rgb(37, 226, 226) 100%
    ) !important;
  }
  span {
    opacity: 1;
    transition-duration: 0.1s;
    color: $white;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1rem;
    font-size: 1.6rem;
    padding-left: 2.4rem;
  }
  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 2.5rem;
    width: 6rem;
    height: 4.2rem;
    border-radius: 0 50% 50% 0;
    transition-duration: 0.2s;
    position: absolute;
    right: 0;
    z-index: 1000;
  }
  &:active {
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2) !important;
  }
  // transition to collapsed position
  .ant-layout-sider-collapsed & {
    width: 4.2rem;
    padding: 0;
    background: linear-gradient(
      to top,
      $primary-brand 0%,
      $secondary-brand 100%
    ) !important;
    // text
    &:hover {
      background: linear-gradient(
        to top,
        #05d466 0%,
        rgb(37, 226, 226) 100%
      ) !important;
    }
    span {
      padding-left: 0;
      transition-duration: 0.1s;
    }
    span:not(.anticon) {
      opacity: 0;
    }
    // icon
    .icon-btn {
      width: 4.2rem;
      border-radius: 50% 50% 50% 50%;
      right: 0;
    }
  }
}


// menu create
.app-menu-create-new {
  top: -1.6rem;
  left: 11rem;
  font-size: 3rem;
  padding: 0;
  pointer-events: auto;
}

.app-create-new-dropdown {
  pointer-events: none;
}

.ant-dropdown-menu-inline-collapsed {
  top: -1.6rem;
  left: 2.8rem;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  display: flex;
  align-items: center;
  line-height: normal;
  &:hover {
    background: $contrast4;
  }
}

// help button on the bottom
.app-side-menu .app-help {
  bottom: 4.8rem !important;
  position: absolute !important;
  z-index: 1;
  width: 100%;
  background-color: $white;
}

.ant-menu-inline-collapsed.app-side-menu .app-help {
  width: 6.4rem;
}

.app-logo-container {
  overflow: hidden;
  background: $darkBlue;
  height: 4.8rem;
  width: 26rem;
  transition: 0.2s;
  .ant-layout-sider-collapsed & {
    width: 6.4rem;
  }
  > div {
    width: 26rem;
    height: 5rem;
    padding: 0.3rem 5.5rem 0 4.5rem;
    transition: 0.2s;
    .ant-layout-sider-collapsed & {
      padding: 0.3rem 10.5rem 0 1.1rem;
    }
  }
}
