@import '../../../style/color';
@import '../../../style/extend';

.app-title.fancy {
  @extend %app-title;
}

.app-title.sans {
  @extend %app-title;
  text-align: center;
  &::after {
    content: none;
  }
}

h5.card-title {
  color: $contrast1;
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 7.5px
}
