.app-organizations-skeleton {
  .ant-skeleton-content .ant-skeleton-paragraph li {
    position: relative;
    z-index: 1;
    margin: 3.5rem 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
    margin: 2.1rem 0;
  }
}

.app-organizations-table {
  .ant-table-tbody > tr:nth-child(even) {
    background: none;
  }
}
