.ant-dropdown-menu {
  padding: 0 0;
  .ant-dropdown-menu-item {
    border-radius: 4px;
    height: 4.8rem;
    font-size: 1.4rem;
    .app-text,
    .ant-upload {
      font-size: 1.4rem;
    }
  }
}
