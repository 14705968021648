@import '../../../style/color';

.app-editable-avatar-container {
  display: inline-block;
  border-radius: 50%;
  .avatar-item {
    position: relative;
    border-radius: 50%;
    .avatar-hover, .avatar-loading {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 4rem;
      color: $white;
      z-index: 1000;
      transition-duration: 0.3s;
    }
  }
}

.app-popover-avatar {
  .ant-dropdown-menu {
    margin-top: -3.4rem;
    .ant-dropdown-menu-item {
      padding: 0;
      width: 20rem;
    }
    span,
    .ant-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
