@import '../../../style/color';

.app-avatar {
  background: linear-gradient(
    to left,
    $primary-brand 0%,
    $secondary-brand 100%
  );

  .ant-avatar-string {
    font-size: 1.8em;
  }
}

.ant-table-cell .ant-avatar-string {
  font-size: 1.2em;
}
