@import '../../../style/color';

.app-administration-container {
  .ant-col {
    margin-bottom: 2.4rem;
  }
}

.app-administration-card {
  height: 100%;
  .ant-card-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    height: 13rem;
    font-size: 6rem;
    color: $primary-brand;
  }
}
