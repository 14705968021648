@import '../../../style/color';

// fix equal height/width to the cards
.app-contractors-list {
  .ant-row {
    display: flex;
    flex-wrap: wrap;
  }
  .app-contractors-incidenttypes-list,
  .app-contractors-incidenttypes-none {
    margin: 10px 0 0;
    overflow-y: auto;
    height: 90px;
  }
  .app-contractors-incidenttypes-list {
    padding-left: 20px;
    list-style-type: circle;
  }
  .app-contractors-incidenttypes-none {
    display: block;
  }
  .app-contractors-list-item {
    width: 100%;
  }
  .app-contractors-card {
    width: 100%;
    height: 100%;
  }
}

.app-contractors-card {
  .ant-card-body {
    height: 100%;
    .ant-card-meta {
      height: 100%;
      .ant-card-meta-avatar {
        height: 100%;
        padding-right: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-card-meta-detail {
        .anticon {
          margin-right: 0.8rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.app-contractors-skeleton {
  padding: 2.5rem;
  padding-top: 3.2rem;
  .ant-skeleton-header {
    padding-right: 2.4rem;
  }
  .ant-skeleton-avatar-circle {
    width: 8rem;
    height: 8rem;
  }
  .ant-skeleton-content .ant-skeleton-paragraph {
    padding-top: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 0.7rem;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 1.5rem;
  }
}
