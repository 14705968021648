@import '../../../style/extend';

.app-btn {
  color: $primary-brand;
  border: 0;
  box-shadow: 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.2);
  &.ant-btn-primary {
    color: $white;
  }
  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-btn-link:hover span {
  text-decoration: underline;
}

button.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding: 0;
}

.ant-btn.ant-btn-icon-only {
  width: 3.2rem;
}

.ant-btn-sm.ant-btn-icon-only {
  width: 2.4rem;
}

.ant-btn-lg.ant-btn-icon-only {
  width: 4rem;
}

