.ant-list-item {
  .actions {
    text-align: right;
    button {
      opacity: 0;
    }
  }
  &:hover {
    .actions button {
      opacity: 1;
    }
  }
}
