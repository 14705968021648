@import '../../../style/color';

.ant-tag.incident-status {
  text-align: center;
  width: 62px;
  color: #fff;

  &.reported {
    background-color: $reported;
    border-color: $reported;
  }

  &.rejected {
    background-color: $rejected;
    border-color: $rejected;
  }

  &.ongoing {
    background-color: $ongoing;
    border-color: $ongoing;
  }

  &.resolved {
    background-color: $resolved;
    border-color: $resolved;
  }
}
