@import '../../../style/color';

.app-maps {
  height: 40vh;
  width: 100%;

  .anticon {
    color: $primary-color;
    font-size: 2.5em;
    transform: translate(-100%, -100%);
  }
}
