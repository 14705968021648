@import '../../../style/color';

.ant-tag.request-status {
  text-align: center;
  width: 62px;
  color: #fff;

  &.opened {
    background-color: $open;
    border-color: $open;
  }

  &.closed {
    background-color: $closed;
    border-color: $closed;
  }
}
