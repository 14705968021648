@import '../../../style/color';

// More margin than usually, since it's a unbordered table
.filters-container + .app-permissions-table {
  margin-top: 30px;
}

.app-permissions-table {
  // Override default antd header styles
  .ant-table-thead > tr > th {
    border: none;
  }

  // First column is sticky
  .ant-table-thead th:first-child, .ant-table-row td:first-child {
    left: 0;
    position: sticky;
    z-index: 2;
  }

  // Center columns content (except for column 1)
  th.ant-table-cell, .app-permission-switch {
    text-align: center;
  }

  // Handle rows between permission subjects
  // (using two divs to handle separate border since table has no margin)
  .app-row-section {
    td:first-child {
      border-top: 1px solid #f0f0f0;
      font-weight: bold;
      padding-bottom: 10px;
      text-transform: uppercase;
      vertical-align: bottom;
    }

    .app-column-section {
      padding: 0;
      text-align: center;
      border-top: 1px solid #f0f0f0;
    }
  }

  .ant-btn-link {
    color: $contrast2;
    font-size: 0.8em;
  }
}
