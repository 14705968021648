@import '../../../../style/color';

.ant-drawer-title .ant-btn {
  float: right;
}

.app-info-profile {
  .ant-drawer-title {
    margin-bottom: 0;

    .app-description {
      margin-bottom: 0;
    }
  }
}
