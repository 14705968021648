@import '../../../style/extend';
@import '../../../style/color';

.app-input {
  @extend %app-input;
}

.app-input-password,
.app-input-search {
  .ant-input {
    @extend %app-input;
  }
}

form .ant-mentions,
form textarea.ant-input {
  margin: 0;
}
