@import '../../../style/extend';

.app-page-header {
    width: 100%;
    padding: 0;
    margin-bottom: 3.2rem;
    background-color: transparent;
    .ant-page-header-heading-left {
        display: flex;
        flex-wrap: wrap;
        overflow: visible;
        .ant-page-header-heading-title {
            @extend %app-title;
        }
        .ant-page-header-heading-sub-title {
            order: 3;
            width: 100%;
        }
        .ant-page-header-heading-tags {
            order: 1;
            padding-top: 1.1rem;
        }
    }
    .ant-page-header-heading-extra {
        padding-top: .6rem;
        position: absolute;
        right: 0;
    }
}

@media (max-width: 576px) {
    .app-page-header
    .ant-page-header-heading
    .ant-page-header-heading-extra {
        order: 4;
        overflow: unset;
        position: static;
    }
}

