@import '../../../style/color';

.app-user-card-popover {
  &.app-card.ant-card-bordered {
    box-shadow: none;
  }
  .ant-card-actions > li {
    text-align: center;
  }
  .avatar-error {
    color: $orange;
    background: $white;
  }
  .anticon {
    margin-right: 0.8rem;
  }
  .ant-tag {
    margin-left: 10px;
  }
}
