@import '../../../style/color';

.app-users-list {
  .ant-row {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-col {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
  }
  .app-users-list-item {
    width: 100%;
  }
  .app-users-card {
    width: 100%;
    height: 100%;
  }
}

.app-users-card {
  .ant-card-body {
    height: 100%;
    .ant-card-meta {
      height: 100%;
      .ant-card-meta-avatar {
        height: 100%;
        padding-right: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-card-meta-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div:first-child {
          margin-right: 10px;
        }
        @media (max-width: 1200px) {
          div:first-child {width: 100%}
        }
      }
      .ant-card-meta-description {
        .anticon {
          margin-right: 0.8rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.app-users-skeleton {
  padding: 2.5rem;
  padding-top: 3.2rem;
  .ant-skeleton-header {
    padding-right: 2.4rem;
  }
  .ant-skeleton-avatar-circle {
    width: 8rem;
    height: 8rem;
  }
  .ant-skeleton-content .ant-skeleton-paragraph {
    padding-top: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 0.7rem;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 1.5rem;
  }
}
