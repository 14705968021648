@import '../../../style/color';

.app-change {
  font-size: 1.5rem;
  color: $contrast2;

  .app-change-arrow {
    position: relative;
    display: inline-block;
    vertical-align: -10px;
    color: #666;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    margin: 10px;
    transform: rotate(45deg);

    &:after,
    &:before {
      content: '';
      box-sizing: border-box;
    }

    &:before {
      right: 0;
      top: -1px;
      position: absolute;
      height: 2px;
      box-shadow: inset 0 0 0 32px;
      transform: rotate(-45deg);
      width: 10px;
      transform-origin: right top;
    }
  }

  .ant-tag {
    margin: 0;
  }
}
