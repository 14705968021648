@import '../../../style/color';

.app-label {
  .anticon-check-circle, .anticon-info-circle {
    margin-left: 5px;
  }

  .anticon-check-circle {
    color: $secondary-brand;
  }
}
