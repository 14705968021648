.app-form {
  .fields-validation-container {
    bottom: 0.2rem;
    margin-right: 0.6rem;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      margin-left: 1rem;
    }
  }
}
