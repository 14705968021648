@import '../../../../style/color';

.incident-intervention-timeline {
  .title {
    font-weight: 500;
    color: $primary-brand;
  }
  .description {
    color: $contrast3;
  }
  .report {
    // color: $red;
  }
  .wait {
    .title,
    .description {
      color: $contrast3;
    }
    .ant-timeline-item-head {
      border-color: $contrast3;
    }
  }
}
