@import '../../../style/color';

.container-multiple-actions-button {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 30px;
    text-align: center;
    z-index: 1000;
    .multiple-actions-button {
        display: inline-block;
        background-color: $primary-brand;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        border-radius: 50px;
        transform: scale(1.3);
        padding: 2px;
        .app-btn {
            box-shadow: none;
        }
    }
}