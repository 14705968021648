@import '../../../style/color';

// Fix table-layout in order to use ellipsis
.app-table .ant-table {
    table {
        table-layout: fixed;
    }
}

// Override headers' style
.ant-table-thead > tr > th {
    color: $contrast3;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.055rem;

    // Action button
    &.action {
        text-align: right;

        button {
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.055rem;
        }
    }
}

// Align headers with/without sorters
.ant-card > .ant-card-body .app-table:first-child .ant-table-thead > tr > th {
    padding: 0;

    &:not(.ant-table-column-has-sorters) {
        padding-left: 16px;
    }

    &.ant-table-selection-column {
        text-align: left;
        padding-left: 8px;
    }
}

// Distinguish row by background color
.app-table .ant-table-tbody > tr:nth-child(even) {
    background: $contrast4;
}

// Override cells' style
.ant-table-tbody > tr {
    & > td {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 0;
        height: 4.8rem;
        cursor: pointer;
        .additional-address {
            font-size: 1.3rem;
            color: $contrast3;
        }
        &.ant-table-column-sort {
            background: none;
        }

        // Action buttons
        &.action {
            text-align: right;
            button {
                opacity: 0;
                margin-left: 0.8rem;
            }
        }
        &.icon {
            .anticon {
                font-size: 1.8rem;
              }
        }
    }

    &:hover .action {
        button {
            opacity: 1;
        }
    }
}

// Override expand icons' style
.ant-table .expand-row-icon {
    margin-left: -1.6rem;
    font-size: 1.2rem;
    width: 1.6rem;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: $primary-brand
}

.ant-table-placeholder .ant-empty {
    margin-top: 1rem;
}

// set padding to the checkboxes to prevent redirection
.ant-card > .ant-card-body {
    .ant-checkbox {
        padding: 4px;
    }
}